import React from 'react';

export default {
    headline: (
        <i>
            Dein <strong>Punktearchiv</strong>
        </i>
    ),
    paragraph:
        'Du möchtest wissen, wem Du bereits Punkte vergeben hast, aber auch wann und wofür? In Deinem Punktearchiv kannst Du das ganz einfach zurückverfolgen.',
    tableHeadings: ['Datum', 'Grund', 'Nutzer', 'Apotheke', 'Punkte'],
};
