import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import Table from '../../../components/Table';
import pointsArchiveDataRenderer from './pointsArchiveDataRenderer';
import {Container, Row, Column, Section} from '../../../layout/Grid';
import lang from '../../../../lang/pages/punktearchiv.lang';
import Pagination from '../../../components/Pagination';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';
import {
    selectAssignments,
    loadPointsAssignments,
    selectCurrentPage,
    selectTotalPages,
} from '../../../../model/points-archive';

export const PointsArchive = ({
    theme,
    archive,
    totalPages,
    currentPage,
    onPageChange,
}) => {
    const handleButtonClick = (page) => {
        if (currentPage !== page) {
            onPageChange(page);
        }
    };
    return (
        <Section theme={theme}>
            <Container width="medium">
                <Row>
                    <Column xs={12}>
                        <Table
                            theme={theme}
                            cols={lang.tableHeadings}
                            rows={archive}
                            dataRenderer={pointsArchiveDataRenderer}
                        />
                    </Column>
                    <Column xs={12}>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            handleButtonClick={handleButtonClick}
                        />
                    </Column>
                </Row>
            </Container>
        </Section>
    );
};

PointsArchive.propTypes = {
    theme: PropTypes.oneOf(sectionThemeNames),
    archive: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            date: PropTypes.string,
            reason: PropTypes.string,
            username: PropTypes.string,
            pharmacy: PropTypes.string,
            points: PropTypes.string,
        })
    ),
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    onPageChange: PropTypes.func,
};

PointsArchive.defaultProps = {
    theme: 'default',
    archive: [],
};

const ConnectedPointsArchive = (props) => {
    const assignments = useSelector(selectAssignments);
    const currentPage = useSelector(selectCurrentPage);
    const totalPages = useSelector(selectTotalPages);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadPointsAssignments());
    }, [dispatch]);

    const handlePageChange = (page) => {
        dispatch(loadPointsAssignments(page));
    };

    return (
        <PointsArchive
            {...props}
            archive={assignments}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            totalPages={totalPages}
        />
    );
};

export default ConnectedPointsArchive;
