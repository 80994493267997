import {createSelector} from 'reselect';

export const selectDomain = (state) => state.pointsArchive;

export const selectAssignments = createSelector(
    selectDomain,
    (domain) => domain.assignments
);

export const selectPaginationData = createSelector(
    selectDomain,
    (domain) => domain.pagination
);

export const selectCurrentPage = createSelector(
    selectPaginationData,
    (pagination) => pagination.currentPage
);

export const selectTotalPages = createSelector(
    selectPaginationData,
    (pagination) => pagination.totalPages
);
