import React from 'react';

import Layout from '../ui/layout/Layout';
import {Section, Container} from '../ui/layout/Grid';
import Headline from '../ui/components/Headline';
import lang from '../lang/pages/punktearchiv.lang';
import Link from '../ui/components/Link';
import Paragraph from '../ui/components/Paragraph';
import PointsArchive from '../ui/domains/sales-agent-user-interactions/PointsArchive';
import {routes} from '../model/navigation';

const Punktearchiv = () => {
    return (
        <Layout>
            <Section>
                <Container width="medium">
                    <Link href={routes.punktevergabe} icon="arrowBack" iconLeft>
                        zurück
                    </Link>
                </Container>
                <Container width="narrow" className="text-center">
                    <Headline type="h1">{lang.headline}</Headline>
                    <Paragraph>{lang.paragraph}</Paragraph>
                </Container>
            </Section>
            <PointsArchive theme="blue" />
        </Layout>
    );
};

export default Punktearchiv;
